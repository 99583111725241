.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a:link, a:visited, a:hover, a:active {
  color: darkseagreen;
}

.splash {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-height: 800px;
  background: url(../public/images/seward.jpg) 50% 50%;
  background-size: cover;
}

.mask {
  color: white;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-height: 800px;  
  background: rgba(0,0,0,0.6);
  z-index: 1;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: rgba(255, 255, 255, 0.5);
  padding: 20px;
}


.foreground {
  color: white;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-height: 800px;
  z-index: 10;
}

.subSplashContent {

}

@media only screen and (max-width: 768px) {
  .splash {
    position: inherit;
    height: 500px;
  }

  .mask {
    height: 500px;
  }

  .foreground {
    display: block;
    position: relative;
  }

  .subSplashContent {
    text-align: center;
    font-size: 0.8em;
  }

  .subSplashContent ol {
    text-align: left;
  }

  .footer {
    display: block;
    position: inherit;
    color: rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.1);
    margin-top: 50px;
    padding: 20px;
  }
} 